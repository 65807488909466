import React from "react";

function Footer() {
  return (
    <div className="footer">
      <h5 className="footerChild">Ⓒ 2021 CYC Company Pte Ltd</h5>
    </div>
  );
}

export default Footer;
